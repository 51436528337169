<template>
  <v-container fluid>
    <v-row class="mt-0">
      <v-col>
        <btn-voltar to="/grupos" />
      </v-col>
    </v-row>

    <grupos-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import grupos from '@/api/grupos'

  export default {

    components: {
      GruposCampos: () => import('./GruposCampos'),
    },

    data () {
      return {
        carregando: false,
        validacaoFormulario: {},
      }
    },

    methods: {
      async salvar (grupo, permissoes) {
        let grupoAdicionado = null

        try {
          this.carregando = true
          this.validacaoFormulario = {}

          const respostaAdicionar = await grupos.inserir(grupo)

          grupoAdicionado = respostaAdicionar.data
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          return
        } finally {
          this.carregando = false
        }

        try {
          this.carregando = true

          await grupos.atualizarPermissoes(grupoAdicionado.id, {
            permissoes: permissoes.join(','),
          })

          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }))
          return
        } catch (e) {
          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Grupo adicionado, mas as permissões não foram salvas, edite e confira' }))
          return
        } finally {
          this.carregando = false
        }
      },
    },
  }
</script>
